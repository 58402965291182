import { books } from './books';
import Book from './Book';
import NavLinks from './NavLinks';

const BookList = () => {
  const findBook = (id) => {
    const book = books.find((book) => book.id === id);
    console.log(book);
  }

  return (
    <>
      <NavLinks />
      <h1 className='page-title'>Best Sellers</h1>
      <section className="booklist">
        {books.map((book, index) => {
          return <Book key={book.id} rank={index + 1} getBook={findBook} {...book} />
        })}
      </section>
    </>
  )
};

export default BookList;