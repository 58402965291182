const Book = ({ title, author, img, description, getBook, id, rank, children }) => {
    const inlineStyle = {
        fontStyle: 'italic',
        fontSize: '0.75rem',
    }

    return (
        <article className="book">
            <h2>{title}</h2>
            <img src={img} alt={title} />
            <h3 style={{ color: 'coral', marginTop: '0.5rem', marginBottom: '0.5rem', fontVariant: 'small-caps' }}>{author}</h3>
            <p style={inlineStyle}>{description}</p>
            {children}
            <button onClick={() => getBook(id)}>Click me</button>
            <span className="rank">#{rank}</span>
        </article>
    );
};

export default Book;