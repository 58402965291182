const NavLinks = () => {
    return (
        <nav className="main-nav">
            <a href="/">Home</a>
            <a href="about.html">About</a>
            <a href="contact.html">Contact</a>
        </nav>
    );
};

export default NavLinks;