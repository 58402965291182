import React from "react";
import ReactDOM from "react-dom/client";

import "./index.css";

//import Greeting from "./greeting";
import BookList from "./booklist";
//import EventExample from "./eventexample";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<BookList />);