import img1 from './images/book-1.jpg';
import img2 from './images/book-2.jpg';
import img3 from './images/book-3.jpg';
import img4 from './images/book-4.jpg';

export const books = [
    {
        id: 1,
        title: "Dragon Moon Sangre Book",
        img: img1,
        author: "Alas F. Troop",
        description: "A secret race as old as time, they have inspired our greatest legends, our grimmest fairy tales, and our grandest nightmares.",
    },
    {
        id: 2,
        title: "The Woman in Me",
        img: img2,
        author: "Britney Spears",
        description: "The Woman in Me is a brave and astonishingly moving story about freedom, fame, motherhood, survival, faith, and hope.",
    },
    {
        id: 3,
        title: "Killers of the Flower Moon",
        img: img3,
        author: "David Grann",
        description: "A masterful work of literary journalism crafted with the urgency of a mystery.",
    },
    {
        id: 4,
        title: "No Brainer",
        img: img4,
        author: "Jeff Kinney",
        description: "In No Brainer, book 18 of the Diary of a Wimpy Kid series from #1 international bestselling author Jeff Kinney, it’s up to Greg to save his crumbling school before it’s shuttered for good.",
    }
];